import active_bg from '@/assets/images/tab_active_bg.png';
import useResponsive from '@/hooks/useResponsive';
import { hideScrollbarX } from '@/utils/cssStyles';
import { Box, Stack, Typography } from '@mui/material';
interface Props {
  tab: string;
  onClickTab: (tab: string, index: number) => void;
  tabList: Array<{
    image_i: string | undefined;
    title: string;
    image: string;
  }>;
}

const HomeTabs: React.FC<Props> = (props: Props) => {
  const isMobile = useResponsive('down', 'md');

  return (
    <Box sx={{ my: 2, ...hideScrollbarX }}>
      <Stack direction="row" spacing={1}>
        {props.tabList.map((item, index) => {
          const isActive = props.tab === item.title;
          return (
            <Box
              key={item.title}
              // variant="contained"
              onClick={() => props.onClickTab(item.title, index)}
              sx={[
                {
                  borderRadius: '5px',
                  background: isActive ? '#36A8F4' : '#232B36',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  px: '16px',
                  py: '12px',
                  '&:hover': {
                    bgcolor: `url(${active_bg})`,
                    cursor: 'pointer',
                  },
                },
                isActive && {
                  backgroundImage: `url(${active_bg})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                },
              ]}
            >
              <Box
                display="flex"
                flexDirection={'row'}
                alignItems="center"
                justifyContent="center"
                sx={{ minWidth: 51 }}
              >
                <img
                  // src={isActive ? item.image : item.image_i}
                  src={item.image}
                  alt=""
                  width={'16px'}
                  height={'16px'}
                  style={{ marginRight: '4px' }}
                />
                <Typography
                  variant="body1"
                  color={isActive ? 'text.main' : 'text.secondary'}
                  fontWeight={500}
                >
                  {item.title}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default HomeTabs;
